	import { Link } from "react-router-dom";
	import "./Navigation.css";
	import { useEffect, useRef } from "react";
	import logoImg from "../../Media/logo/RADS_ChainLogo.svg";


	export default function Navigation(props) {
		const navigationListRef = useRef();
		const logoImgRef = useRef();

		const randomOffsetCoeff = Math.random() / 2;

		useEffect(() => {
			const handleResize = () => {
				let navigationItems = navigationListRef.current.children;

				let radius = Math.min(navigationListRef.current.offsetHeight, navigationListRef.current.offsetWidth) / 2;

				let heightOffset = window.innerHeight / 2;
				let widthOffset = window.innerWidth / 2;

				let angleIncrement = (2 * Math.PI) / navigationItems.length;
				let angleOffset = randomOffsetCoeff * angleIncrement;

				for (let i = 0; i < navigationItems.length; i++) {
					let itemAngle = angleOffset + i * angleIncrement;

					let x = radius * Math.cos(itemAngle);
					let y = radius * Math.sin(itemAngle);

					let itemRect = navigationItems[i].getBoundingClientRect();

					let top = heightOffset + y;
					top -= (y > 0) ? itemRect.height : 0;
					navigationItems[i].style.top = top + "px";

					let left = widthOffset + x;
					left -= (x > 0) ? itemRect.width : 0;
					navigationItems[i].style.left = left + "px";
				
					navigationItems[i].style.flexDirection = (x > 0) ? "row-reverse" : "row";
				}

				logoImgRef.current.style.height = radius * 2 - 150 + "px";
				logoImgRef.current.style.width = radius * 2 - 150 + "px";
			};

			window.addEventListener("resize", handleResize);
			handleResize();

			return () => {
				window.removeEventListener("resize", handleResize);
			};
		});

		return (
			<div className="navigation">
				<div className="navigation-circle-container">
					<ul className="navigation-circle-list" ref={navigationListRef}>
						<li className="navigation-circle-item">
							<Link
								to={"/archive"}
								className="navigation-link"
							>
								archive
							</Link>
						</li>

						<li className="navigation-circle-item">
							<Link
								to={"https://instagram.com/rausausdemschlamm"}
								target="_blank"
								className="navigation-link"
							>
								instagram
							</Link>
						</li>

						<li className="navigation-circle-item">
							<Link
								to={"/shop"}
								className="navigation-link"
							>
								shop <br /> (coming soon)
							</Link>
						</li>

						<li className="navigation-circle-item">
							<Link
								to={"https://open.spotify.com/artist/5zpx3DfGvySjxtBiFlV7kp?si=gsCYePI-TnmKeueIbrNNcw"}
								target="_blank"
								className="navigation-link"
							>
								Jeroen Wijne
							</Link>
						</li>

						{/* <li className="navigation-circle-item">
							<Link
								to={"/upcoming"}
								className="navigation-link"
							>
								upcoming
							</Link>
						</li> */}

						<li className="navigation-circle-item">
							<Link
								to={"https://open.spotify.com/artist/2BKb8k4bGFSUpJTcgaOo4k?si=ckpm7xV_RHaqhk9Ynt8Rpw"}
								target="_blank"
								className="navigation-link"
							>
								TSCHI&#857;CK
							</Link>
						</li>
					</ul>
				</div>

				<img
					className="navigation-circle-center-logo-img"
					src={logoImg}
					ref={logoImgRef}
					alt=""
				/>
			</div>
		);
	}