import "./Login.css";
import LoginManager from "../../Services/LoginManager/LoginManager";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
	const [loginData, setLoginData] = useState({
		username: "",
		password: ""
	});
	const navigate = useNavigate();

	useEffect(() => {
		LoginManager.isLoggedIn()
			.then(res => {
				console.log(res);
				// if (res) {
				// 	console.log(res);
				// 	navigate("/eventmanager");
				// }
			});
	}, []);


	const handleChange = (e) => {
		let value = e.target.value;

		setLoginData({ ...loginData, [e.target.name]: value });
	};

	const login = (e) => {
		e.preventDefault();

		LoginManager.login(loginData)
			.then(res => {
				// window.location.reload();
			});
	}

	return (
		<div className="login">
			<form className="login-form" onSubmit={login}>
				<h2 className="login-form-heading">Login</h2>

				<div className="login-form-input-container">
					<input
						className="login-form-input"
						value={loginData.username}
						type="text"
						name="username"
						placeholder="username"
						onChange={handleChange}
					/>
					<input
						className="login-form-input"
						value={loginData.password}
						type="password"
						name="password"
						placeholder="password"
						onChange={handleChange}
					/>
				</div>

				<button type="submit" className="login-form-button">login</button>
			</form>
		</div>
	);
};