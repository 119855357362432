class LoginManager {
	static async isLoggedIn() {
		// const response = await fetch("http://localhost/rausausdemschlamm.at_server/Login/checkLogin.php", {
		const response = await fetch("/server/Login/checkLogin.php", {
			// credentials: "include"
		});
		return response.json();
	}

	static async login(loginData) {
		const response = await fetch("http://localhost/rausausdemschlamm.at_server/Login/login.php", {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({
				loginData: loginData
			})
		});
		return response.json();
	}

	static async logout() {
		const response = await fetch("http://localhost/rausausdemschlamm.at_server/Login/logout.php", {
			credentials: "include"
		});
		return response.json();
	}
}

export default LoginManager;