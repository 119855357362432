import './App.css';
import { Route, Routes } from 'react-router-dom';
import Gallery from './Content/Gallery/Gallery';
import Navigation from './Content/Navigation/Navigation';
import Upcoming from './Content/Upcoming/Upcoming';
import Contact from './Content/Contact/Contact';
import NotFound from './Content/NotFound/NotFound';
import Events from './Content/Events/Events';
import Login from './Content/Login/Login';
import EventManager from './Content/EventManager/EventManager';
import { ProtectedRoute } from './Components/ProtectedRoute/ProtectedRoute';

function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Navigation />} />
				<Route path="/archive" element={<Gallery />} />
				<Route path="/events" element={<Events />} />

				<Route path="/shop" element={<NotFound />} />
				{/* <Route path="/contact" element={<Contact />} /> */}


				<Route path="/login" element={<Login />} />
				{/* <Route
					path="/eventmanager"
					element={
						<ProtectedRoute>
							<EventManager />
						</ProtectedRoute>
					}
				/> */}


				<Route path="*" element={<NotFound />} />
			</Routes>
		</div>
	);
}

export default App;
